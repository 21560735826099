import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StarHalf from '@material-ui/icons/StarHalf';
import Grid from '@material-ui/core/Grid';
import CopyrightIcon from '@material-ui/icons/Copyright';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";

interface Props {}

const useStyles = makeStyles((theme) =>({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      },
      title: {
        fontWeight: 800,
        fontFamily: 'Sansita Swashed',
        flexGrow: 1,
        display: 'block',
      },
      icon:{
        position: 'relative',
        top:'5px'
      },
      cIcon:{
        position: 'relative',
        top:'3px',
        fontSize: '1.0rem',
      },
      infoContent:{
        textAlign:'center',
      },
      emailLink:{
        textDecoration:'none',
        color:'inherit',
      }
  }),
);

export const Footer: React.FC<Props> = () => {

    const classes = useStyles();
    
    return (
        <footer className={classes.footer}>
          <Grid container className={classes.infoContent}>
          <Grid item xs={4}>
        <Typography variant="body1" className={classes.title}>Kontakt</Typography>
        <Typography variant="body2"><a href="mailto:kontakt.rockgaweda@gmail.com" className={classes.emailLink}>kontakt.rockgaweda<br />@gmail.com</a></Typography>
        </Grid>
        <Grid item xs={4}>
        <Typography variant="body1" className={classes.title}> 
        <Link to={{pathname: "https://www.facebook.com/rockgaweda"}} target="_blank" style={{ textDecoration: "none" }}>     
        <IconButton aria-label="facebook">
        <FacebookIcon/>
      </IconButton>
      </Link>
      </Typography>
        </Grid>
        <Grid item xs={4}>
        <Typography variant="body1" className={classes.title}>R<StarHalf className={classes.icon}/>G</Typography>
        <Typography variant="body2">Copyright <CopyrightIcon className={classes.cIcon}/> {new Date().getFullYear()} </Typography>
        <Typography variant="body2">Kamil Olszewski</Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      </footer>
      );
};