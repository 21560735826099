import React, { useState } from "react";
import { SongBase } from "../models/Song";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { defineImage } from "../helpers/image-helper";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";
import { Paths } from "../helpers/constants";

type CardProps = {
  song: SongBase;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      transform: "translate(-2.5%, -2.5%)",
      transition: "transform 0.5s",
    },
    position: "relative",
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.5)",
    transition: "transform 0.5s",
  },
  cardMedia: {
    paddingTop: "80%", // 16:9
    backgroundPosition: "center top",
  },
  cardContent: {
    flexGrow: 1,
  },
  viewsBox: {
    float: "right"
  },
  hoverBox: {
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.5,
    textAlign: "center"
  },
  boxWithIcon: {
    marginTop: "50%"
  },
  visibilityIcon:{
    color: theme.palette.grey[50]
  }
}));

export const SongCard = ({ song }: CardProps) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);

  function defineViews(views: number) {
    if (views > 999 && views < 999999) {
      let count = Math.round(views) / 1000;
      return `${count.toLocaleString("en-IN", {
        maximumSignificantDigits: 2
      })} tys.`;
    } else if (views > 999999) {
      let count = Math.round(views) / 1000000;
      return `${count.toLocaleString("en-IN", {
        maximumSignificantDigits: 2
      })} mln.`;
    }

    return views.toString();
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      {song && (
        <Link to={Paths.stories.viewStory(song.bandLink, song.songLink)} style={{ textDecoration: "none" }}>
          <Card
            className={classes.card}
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {isHovering && (<Box className={classes.hoverBox}>
              <Box className={classes.boxWithIcon}>
                <VisibilityIcon className={classes.visibilityIcon} fontSize="large" />
              </Box>
            </Box>)}
            <CardMedia
              className={classes.cardMedia}
              image={defineImage(song.imageUrl)}
              title={`Interpretacja piosenki ${song.songName}`}
              aria-label={`Interpretacja piosenki ${song.songName}`}
            />
            <CardContent className={classes.cardContent}>
              <Box
                mb={0.1}
                borderColor="transparent"
                className={classes.viewsBox}
              >
                <Chip
                  size="small"
                  label={defineViews(song.views)}
                  icon={<VisibilityIcon />}
                  variant="outlined"
                />
              </Box>
              <br></br>
              <Typography gutterBottom variant="h6" component="h2">
                {song.songName}
              </Typography>
                <Typography color="textSecondary">
                  {song.bandName}
                </Typography>
            </CardContent>
          </Card>
        </Link>
      )}
    </Grid>
  );
};
