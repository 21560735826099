import React from "react";
import { SongCard } from "../components/SongCard";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TopStoriesCategory } from './../Enums'
import useAxios from 'axios-hooks';
import { LoadingStar } from "../components/loading-star";
import { AppAlert } from "../components/app-alert";
import { AlertTypes, APIUrl } from '../Enums';
import { getURL } from '../helpers/url-helper';
import Fab from '@material-ui/core/Fab';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '@material-ui/core/Tooltip';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import { Link } from "react-router-dom";
import { Paths } from "../helpers/constants";
import { SongBase } from "../models/Song";


type TopStoriesProps = {
  title: string,
  category: TopStoriesCategory
};

const useStyles = makeStyles({
  header: {
    fontFamily: 'Sansita Swashed',
    fontWeight: 500,
  },
  fabStyle: {
    margin: 'auto'
  }
});

export const TopStories = ({ title, category }: TopStoriesProps) => {

  const classes = useStyles();
  const theme = useTheme();

  var urlApi = '';
  var urlOnClick = '';

  if (category === TopStoriesCategory.Views) {
    urlApi = getURL(APIUrl.TopStories);
    urlOnClick = Paths.stories.top;
  }
  else if (category === TopStoriesCategory.News) {
    urlApi = getURL(APIUrl.FreshStories);
    urlOnClick = Paths.stories.fresh;
  }

  const [{ data, loading, error }] = useAxios<SongBase[]>({
    method: 'GET',
    url: urlApi
  });

  return (
    <div>
      <Container style={{ paddingBottom: theme.spacing(8), paddingTop: theme.spacing(8) }} maxWidth="md">
        <Typography key={`key-for-header-${title}`} className={classes.header} component="h3" variant="h3" align="left" color="textPrimary">
          <DoubleArrowRoundedIcon />{title}
        </Typography>
        <br></br>
        <Grid container spacing={4}>
          {loading && <LoadingStar />}
          {error && <AppAlert type={AlertTypes.Error} text='Nie udało się pobrać danych' strongText='Przepraszamy' />}
          {data && data.map((story) => {
            return (<SongCard key={`${story?.bandName}-${story?.songName}-${story?.id}-${title}`} song={story} />);
          })}
          {data &&
            <div className={classes.fabStyle}>
              <br />
              <Link to={urlOnClick} style={{ textDecoration: "none" }}>
                <Tooltip title="Zobacz więcej" placement="bottom">
                  <Fab color="secondary" aria-label="zobacz więcej">
                    <MoreHorizIcon />
                  </Fab>
                </Tooltip>
              </Link>
            </div>
          }
        </Grid>
      </Container>
    </div>
  );
}