import React, { useCallback } from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import ButtonBase from '@material-ui/core/ButtonBase';
import BandsWebp from '../images/bands.webp';
import SongsWebp from '../images/songs.webp';
import MapWebp from '../images/map.webp';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import { Paths } from "../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontFamily: 'Sansita Swashed',
    fontWeight: 500,
  },
  root: {
    margin: 'auto',
    marginTop: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
}));

export const SearchingPanel = () => {

  const history = useHistory();
  const classes = useStyles();
  const themePanel = useTheme();

  const handleOnClickBands = useCallback(() => history.push(Paths.bands.searchBands("A-B")), [history]);
  const handleOnClickSongs = useCallback(() => history.push(Paths.stories.searchStories("A-B")), [history]);
  const handleOnClickMaps = useCallback(() => history.push(Paths.map.map), [history]);

  return (

    <Container style={{ paddingTop: themePanel.spacing(8), paddingBottom: themePanel.spacing(8), }} maxWidth="md">
      <Typography key={`key-for-search-header`} className={classes.header} style={{ position: "relative" }} component="h3" variant="h3" align="left" color="textPrimary">
        <DoubleArrowRoundedIcon /> Wyszukaj
      </Typography>
      <Grid container spacing={4}>
        <div className={classes.root}>
          <ButtonBase
            focusRipple
            key='Zespoły'
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: '33%',
            }}
            onClick={handleOnClickBands}
          >
            <span
              title={"Interpretacja piosenek - wyszukaj po zespole"}
              aria-label={"Interpretacja piosenek - wyszukaj po zespole"}
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${BandsWebp})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="h3"
                color="inherit"
                className={classes.imageTitle}
              >
                Zespoły
              </Typography>
            </span>
          </ButtonBase>
          <ButtonBase
            focusRipple
            key='Piosenki'
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: '33%',
            }}
            onClick={handleOnClickSongs}
          >
            <span
              title={"Interpretacja piosenek - wyszukaj po nazwie utworu"}
              aria-label={"Interpretacja piosenek - wyszukaj po nazwie utworu"}
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${SongsWebp})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="h3"
                color="inherit"
                className={classes.imageTitle}
              >
                Piosenki
              </Typography>
            </span>
          </ButtonBase>
          <ButtonBase
            focusRipple
            key='Mapa'
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: '33%',
            }}
            onClick={handleOnClickMaps}
          >
            <span
              title={"Interpretacja piosenek - wyszukiwanie mapa"}
              aria-label={"Interpretacja piosenek - wyszukiwanie mapa"}
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${MapWebp})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="h3"
                color="inherit"
                className={classes.imageTitle}
              >
                Mapa
              </Typography>
            </span>
          </ButtonBase>
        </div>
      </Grid>
    </Container>

  );
}