import React from 'react';
import { TopStories } from "./top-stories";
import { HelloContent } from "./hello-content";
import { TopStoriesCategory } from '../Enums'
import StarHalf from '@material-ui/icons/StarHalf';
import { Footer } from '../components/footer';
import { SearchingPanel } from '../components/searching-panel';
import { MetaDecorator } from '../helpers/metaDecorator';
import { CanonicalLink } from '../helpers/constants';

interface Props { }

export const Home: React.FC<Props> = () => {
    const htmlContent = ["R", <StarHalf key="key-for-icon-star-home-hello-content" style={{ position: 'relative', top: '13px', fontSize: '4.0rem' }} />, "ck Gawęda"];
    const textConntent = ["Każda rockowa piosenka ma swoją ", <strong key="strong-text-conntent-1">historię</strong>, " i ", <strong key="strong-text-conntent-2">interpretację</strong>, ", poznaj ją razem z nami."]
    return (
        <div>
            <MetaDecorator
                title="Rock Gawęda | Poznaj interpretację piosenek i ich historię"
                description="Rock Gawęda to zbiór interpretacji piosenek oraz historii dotyczących powstawania polskich rockowych hitów. Wśród nich historię:Lady Pank, Perfect, T.Love, Dżem"
                canonicalLink={CanonicalLink.home} />
            <HelloContent key="key-for-hello-home-page" text={textConntent} html={htmlContent} />
            <SearchingPanel />
            <TopStories key="key-for-top-stories" title="Najczęściej czytane" category={TopStoriesCategory.Views} />
            <TopStories key="key-for-fresh-stories" title="Najnowsze historie" category={TopStoriesCategory.News} />
            <Footer />
        </div>
    );
};