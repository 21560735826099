import React from 'react';
import { Helmet } from "react-helmet-async";
import ogLogo from "../images/rockgawedaOG.jpg"

interface Props {
  title: string,
  description: string,
  canonicalLink: string,
  ogImage?: string
}

export const MetaDecorator = ({ title, description, canonicalLink, ogImage }: Props) => {

  const hostName = 'https://rockgaweda.pl';
  const imageAlt = 'Obraz przedstawia logo strony- biały napis Rock Gawęda na ciemnym tle kaset magnetofonowych.';

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      { /* Facebook tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* TODO: KO change on hostname + ogLogo */}
      <meta property="og:image" content={ogImage ? hostName + ogImage : hostName + ogLogo} />
      {/* TODO: KO change on hostname +  window.location.pathname + window.location.search*/}
      <meta property="og:url" content={hostName + window.location.pathname + window.location.search} />
      { /* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={canonicalLink} />
    </Helmet>
  );
}