import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import {AlertTypes} from '../Enums'

interface Props {
  strongText: string,
  text: string,
  type: AlertTypes
}


export const AppAlert = ({strongText, text, type}: Props) =>{

  const alertDictionary: Map<AlertTypes, string> = new Map([
  [AlertTypes.Error, 'Błąd'],
  [AlertTypes.Warning, 'Uwaga'],
  [AlertTypes.Success, 'Sukces'],
  [AlertTypes.Info, 'Info']
]);

    return (
      <div style = {{margin: 'auto'}}>
      <Alert severity={type}>
        <AlertTitle>{alertDictionary.has(type) && alertDictionary.get(type)}</AlertTitle>
          {text} {strongText && '—' && <strong>{strongText}</strong> }
      </Alert>
    </div>
    );
}