import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

interface Props {
  html: {},
  text: {}
}

export const HelloContent = ({html, text}: Props) =>{
    const theme = useTheme();

    return (
        <div style = {{padding: theme.spacing(20, 0, 6)}}>
        <Container maxWidth="sm">
          <Typography key="key-for-hello-contect-html-element-h1" style = {{fontFamily: 'Sansita Swashed',  fontWeight:700}} component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              {html}
          </Typography>
          <Typography key="key-for-hello-contect-text-element-h5" style = {{fontFamily: 'Sansita Swashed',  fontWeight:400}} variant="h5" align="center" color="textSecondary" paragraph>
         {text}
          </Typography>
        </Container>
      </div>
    );
}